<template>
  <div class="flex flex-col lg:flex-row">
    <section class="te-bg-banner-diagnoses flex grow flex-col justify-center px-4 md:flex-row">
      <div class="te-title-banner-diagnoses flex items-center justify-center pt-4 md:pt-0">
        <picture>
          <source
            media="(max-width: 575.98px)"
            srcset="~/assets/images/Te/TeCommon/Banner/Diagnoses/banner-diagnoses-sp.png"
            width="343"
            height="69"
          >
          <img
            class="te-no1-image"
            src="~/assets/images/Te/TeCommon/Banner/Diagnoses/banner-diagnoses-pc.png"
            alt=""
            width="483"
            height="101"
            loading="lazy"
          >
        </picture>
      </div>
      <div class="pb-8 pt-5 text-center md:ml-8 md:py-8">
        <a
          class="co-btn-secondary !border-co-gray-50 !text-co-gray-50"
          href="/diagnoses/top"
        >今すぐ診断する</a>
      </div>
    </section>
  </div>
</template>

<style scoped>
/* // オススメコース診断帯　背景色 */
.te-bg-banner-diagnoses {
  background-color: #49b9f4;
  background-image: url('@/assets/images/Te/TeCommon/Banner/Diagnoses/bg-diagnoses.png');
  background-repeat: repeat;
}

/* // 受講生数No.1帯 */
.te-title-banner-diagnoses {
  width: 100%;
  @media (min-width: 768px) {
    width: 400px;
  }
  @media (min-width: 992px) {
    width: 483px;
  }
}
</style>
